import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'

import type { StringExpirableData } from './tracking/expirableData'
import { SessionIdData, SessionIdDataMapper, StringExpirableDataMapper } from './tracking/expirableData'
import { sessionInitFalse, sessionInitTrue } from './tracking/trackingConstants'
import { canUseDom } from './utils/canUse'
import { NAVIGATION_SESSION_INIT } from './userConstants'

// STORAGE KEYS
export const EMAIL_HIDDEN_STORAGE = 'ivts-emailHidden'
export const EMAIL_STRONG_STORAGE = 'ivts-emailStrong'
export const EMAIL_STRONGER_STORAGE = 'ivts-emailStronger'
export const NAVIGATION_PREVIOUS_STORAGE = 'ivts-previousPageDatas'
export const VISITOR_TYPE_STORAGE = 'ivts-visitorType'
export const FIRST_VISIT_STORAGE = 'ivts-firstVisit'
export const EVENT_TO_CONSENT_STORAGE = 'ivts-event-pending'
export const EVENTS_PUSH_STORAGE = 'ivts-events-push'
export const EVENTS_CLICK_STORAGE = 'ivts-events-click'
export const TRACE_ID_STORAGE = 'ivts-trace-id'
export const CONSENT_VENDORS_S2S_STORAGE = 'ivts-con-v'
export const CONSENT_VENDORS_ALL_STORAGE = 'ivts-con-v-all'
export const CONSENT_VENDORS_FILTER_STORAGE = 'ivts-con-v-filter'
export const CONSENT_STRING_STORAGE = 'ivts-con-s'
export const CONSENT_ID_STORAGE = 'ivts-con-id'
export const EXEMPT_ID_STORAGE = 'ivts-con-ex'
export const ATTRIBUTION_MEDIUM_STORAGE = 'attribution-medium'
export const ATTRIBUTION_SOURCE_STORAGE = 'attribution-source'
export const ATTRIBUTION_CAMPAIGN_STORAGE = 'attribution-campaign'
export const ATTRIBUTION_CONTENT_STORAGE = 'attribution-content'
export const ATTRIBUTION_TERM_STORAGE = 'attribution-term'
export const ATTRIBUTION_CRM_STORAGE = 'attribution-crm'
export const ATTRIBUTION_CLID_STORAGE = 'attribution-clid'
export const ATTRIBUTION_GCLID_STORAGE = 'attribution-gclid'
export const ITINERARY_ID_STORAGE = 'itineraryId'
export const CRITEO_ID = 'ivts-criteo-id'
export const ZEMANTA_ID_STORAGE = 'ivts-zemanta-id'
export const SEARCH_USAGE_STORAGE = 'ivts-search-usage'
export const SESSION_ID_STORAGE = 'ivts-session-id'
export const UE_CONSENT_V2 = 'euconsent-v2'

export interface PreviousData {
  previousPageName: string
  clickPosition: string
}

export class StorageSdk {
  localStorage = (): Storage | null => (canUseDom() ? localStorage : null)

  sessionStorage = (): Storage | null => (canUseDom() ? sessionStorage : null)

  getNavigationPrevious = (): undefined | PreviousData => {
    const item = this.sessionStorage()?.getItem(NAVIGATION_PREVIOUS_STORAGE)

    if (!item) {
      return undefined
    }

    try {
      const previousData = JSON.parse(item, (_, value) => value || undefined)

      return previousData
    } catch (_) {
      return undefined
    }
  }

  getExpirableStringFromLocal(key: string): StringExpirableData | undefined {
    return this.getExpirableString(key, this.localStorage())
  }

  getExpirableStringFromSession(key: string): StringExpirableData | undefined {
    return this.getExpirableString(key, this.sessionStorage())
  }

  private getExpirableString(key: string, storage: Storage | null): StringExpirableData | undefined {
    const data = StringExpirableDataMapper.fromJSON(storage?.getItem(key))

    if (data?.isExpired()) {
      storage?.removeItem(key)

      return undefined
    }

    return data
  }

  getSessionId(): SessionIdData {
    const current = SessionIdDataMapper.fromJSON(this.localStorage()?.getItem(SESSION_ID_STORAGE))

    if (current.isExpired()) {
      return this.resetSessionId()
    }

    if (canUseDom()) {
      const init = Cookies.get(NAVIGATION_SESSION_INIT)
      // gestion de l'init
      const initValue = init === sessionInitFalse ? init : current.init

      return this.saveSessionId(current.sessionId, initValue)
    }

    return new SessionIdData(new Date(), uuid(), sessionInitTrue).resetExpirationDate()
  }

  resetSessionId(): SessionIdData {
    return this.saveSessionId(uuid().toString())
  }

  saveSessionId(id: string, initValue = sessionInitTrue): SessionIdData {
    const sessionIdData = new SessionIdData(new Date(), id, initValue).resetExpirationDate()
    this.localStorage()?.setItem(SESSION_ID_STORAGE, SessionIdDataMapper.toJSON(sessionIdData))

    return sessionIdData
  }

  saveExemptId(exemptId: string): void {
    if (exemptId.length > 0) this.localStorage()?.setItem(EXEMPT_ID_STORAGE, exemptId)
  }

  saveConsentId(consentId: string): void {
    if (consentId.length > 0) this.localStorage()?.setItem(CONSENT_ID_STORAGE, consentId)
  }
}
