import { type AxiosRequestConfig } from 'axios'
import { add as addToDate, isPast } from 'date-fns'
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'

import {
  ClidData,
  CrmData,
  EmailData,
  GclidData,
  StringExpirableDataMapper,
  VisitorTypeDataMapper,
  ZaidData,
} from './tracking/expirableData'
import { canUseDom, canUseWindow } from './utils/canUse'
import { APP_VERSION_VALUE, BFF_KEY_VALUE } from './build'
import { VisitorType } from './enums'
import type { TrackedEvent, TrackedEventEventTypeEnum, UserAccountFound } from './generated'
import { type AugmentedAxiosRequestConfig } from './interceptors.types'
import {
  ATTRIBUTION_CLID_STORAGE,
  ATTRIBUTION_CRM_STORAGE,
  ATTRIBUTION_GCLID_STORAGE,
  CONSENT_ID_STORAGE,
  CONSENT_STRING_STORAGE,
  CONSENT_VENDORS_S2S_STORAGE,
  CRITEO_ID,
  EMAIL_HIDDEN_STORAGE,
  EMAIL_STRONG_STORAGE,
  EMAIL_STRONGER_STORAGE,
  EVENTS_CLICK_STORAGE,
  EVENTS_PUSH_STORAGE,
  EXEMPT_ID_STORAGE,
  SEARCH_USAGE_STORAGE,
  StorageSdk,
  TRACE_ID_STORAGE,
  UE_CONSENT_V2,
  VISITOR_TYPE_STORAGE,
  ZEMANTA_ID_STORAGE,
} from './storage'
import {
  ABTASTY_HEADER,
  APP_VERSION_HEADER,
  ATTRIBUTION_CAMPAIGN_HEADER,
  ATTRIBUTION_CLID_HEADER,
  ATTRIBUTION_CONTENT_HEADER,
  ATTRIBUTION_CRM_HEADER,
  ATTRIBUTION_EFFI_ID_HEADER,
  ATTRIBUTION_EFFI_ID2_HEADER,
  ATTRIBUTION_GCLID_HEADER,
  ATTRIBUTION_ID_COMPTEUR_HEADER,
  ATTRIBUTION_MEDIUM_HEADER,
  ATTRIBUTION_PREX_HEADER,
  ATTRIBUTION_REFERRER_HEADER,
  ATTRIBUTION_SOURCE_HEADER,
  ATTRIBUTION_TERM_HEADER,
  BFF_KEY_HEADER,
  CHANNEL_HEADER,
  CLIENT_APP_ID_HEADER,
  CONSENT_EXEMPT_ID_HEADER,
  CONSENT_ID_HEADER,
  CONSENT_STRING_HEADER,
  CONSENT_VENDORS_HEADER,
  CRITEO_ID_HEADER,
  DEVICE_CLASS_HEADER,
  DEVICE_OS_VERSION_HEADER,
  EFFI_ID,
  EFFI_ID2,
  EMAIL_HIDDEN_HEADE,
  EMAIL_STRONG_HEADER,
  EMAIL_STRONGER_HEADER,
  ENV_HEADER,
  FLAGSHIP_HEADER,
  GCLID,
  ID_COMPTEUR,
  LOCALE_HEADER,
  NAVIGATION_CURRENT_HEADER,
  NAVIGATION_PAGE_PARAMS,
  NAVIGATION_PREVIOUS_HEADER,
  NAVIGATION_SESSION,
  POST_BACK_ID,
  PREX,
  SEARCH_USAGE_HEADER,
  TRACE_ID_HEADER,
  VIRTUAL_ENV_NAME,
  VISITOR_TYPE_HEADER,
  WIZ_CLID,
  WIZ_COMPAIGN,
  WIZ_CONTENT,
  WIZ_CRM,
  WIZ_MEDIUM,
  WIZ_SOURCE,
  WIZ_TERM,
  ZEMANTA_ID,
  ZEMANTA_ID_HEADER,
} from './userConstants'

// COOKIE KEYS
export const VISITOR_ID_COOKIE = 'x-visitor-id'
export const VISITOR_ID_COOKIE_CHANGED = 'x-visitor-id-changed'

// On souhaite renouveler le token 10 minutes avant l'expiration réelle.
const EXPIRATION_LEEWAY = 60 * 2 // in seconds

export const FLAGSHIP_USER_CAMPAIGNS = 'flagship-user-campaings'

export const attributionParamsToTrack: string[] = [
  WIZ_SOURCE,
  WIZ_MEDIUM,
  WIZ_COMPAIGN,
  WIZ_TERM,
  WIZ_CONTENT,
  WIZ_CLID,
  GCLID,
  POST_BACK_ID,
  WIZ_CRM,
]

export const base64Format = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export const crmLength = 64

export class DeviceSettings {
  readonly clientMarket: string

  readonly channel: string

  readonly deviceId: string

  readonly env: string

  readonly osVersion?: string

  readonly deviceClass?: string

  readonly virtualEnvName?: string

  constructor(
    clientMarket: string,
    channel: string,
    deviceId: string,
    env: string,
    osVersion?: string,
    deviceClass?: string,
    virtualEnvName?: string
  ) {
    this.clientMarket = clientMarket
    this.channel = channel
    this.deviceId = deviceId
    this.env = env
    this.osVersion = osVersion
    this.deviceClass = deviceClass
    this.virtualEnvName = virtualEnvName
  }
}

export class User {
  deviceSettings: DeviceSettings

  constructor(deviceSettings: DeviceSettings) {
    this.deviceSettings = deviceSettings
  }
}

type RefreshTokenDataEvent =
  | {
      type: 'REFRESH_TOKEN_DATA'
      payload: UserAccountFound
    }
  | {
      type: 'REFRESH_TOKEN_DATA_ERROR'
      payload: unknown
    }

export type RefreshTokenDataEventListener = (event: RefreshTokenDataEvent) => void

class RefreshTokenDataEventEmitter {
  #listeners: RefreshTokenDataEventListener[] = []

  subscribe(listenerToAdd: RefreshTokenDataEventListener): void {
    this.#listeners.push(listenerToAdd)
  }

  unsubscribe(listenerToRemove: RefreshTokenDataEventListener): void {
    this.#listeners = this.#listeners.filter((listener) => listener !== listenerToRemove)
  }

  dispatch(event: RefreshTokenDataEvent): void {
    this.#listeners.forEach((listener) => listener(event))
  }
}

export class UserSdk {
  readonly user: User

  readonly #refreshTokenDataEventEmitter = new RefreshTokenDataEventEmitter()

  readonly storageSdk: StorageSdk

  #expiresAt?: Date

  constructor(
    user: User,
    refreshTokenDataEventEmitter = new RefreshTokenDataEventEmitter(),
    storageSdk: StorageSdk = new StorageSdk()
  ) {
    this.user = user
    this.#refreshTokenDataEventEmitter = refreshTokenDataEventEmitter
    this.storageSdk = storageSdk
  }

  copyWithUser(user: User): UserSdk {
    const copy = new UserSdk(user, this.#refreshTokenDataEventEmitter, this.storageSdk)
    copy.#expiresAt = this.#expiresAt

    return copy
  }

  subscribeRefreshTokenDataEventListener(listenerToAdd: RefreshTokenDataEventListener): void {
    this.#refreshTokenDataEventEmitter.subscribe(listenerToAdd)
  }

  unsubscribeRefreshTokenDataEventListener = (listenerToRemove: RefreshTokenDataEventListener): void => {
    this.#refreshTokenDataEventEmitter.unsubscribe(listenerToRemove)
  }

  notifyRefreshTokenData = (data: UserAccountFound): void => {
    this.updateTokenData(data)
    this.#refreshTokenDataEventEmitter.dispatch({
      type: 'REFRESH_TOKEN_DATA',
      payload: data,
    })
  }

  notifyRefreshTokenError = (error: unknown): void => {
    this.resetTokenData()
    this.#refreshTokenDataEventEmitter.dispatch({
      type: 'REFRESH_TOKEN_DATA_ERROR',
      payload: error,
    })
  }

  isRefreshTokensNeeded = (): boolean => Boolean(this.#expiresAt && isPast(this.#expiresAt))

  setInvalidTokenData = (): void => {
    this.#expiresAt = new Date(0)
  }

  resetTokenData = (): void => {
    this.#expiresAt = undefined
  }

  updateTokenData = (data: UserAccountFound): void => {
    this.#expiresAt = addToDate(new Date(), { seconds: data.expireIn - EXPIRATION_LEEWAY })
  }

  updateDeviceSettings = (settings: DeviceSettings): User => {
    this.user.deviceSettings = settings

    return this.user
  }

  updateUserConsentData = (
    userConsentedVendors: string,
    userConsentString: string,
    consentId?: string,
    exemptId?: string
  ): User => {
    this.storageSdk.localStorage()?.setItem(CONSENT_VENDORS_S2S_STORAGE, userConsentedVendors)
    this.storageSdk.localStorage()?.setItem(CONSENT_STRING_STORAGE, userConsentString)
    if (consentId) this.storageSdk.saveConsentId(consentId)
    if (exemptId) this.storageSdk.saveExemptId(exemptId)

    return this.user
  }

  getTraceId(): string {
    return this.storageSdk.localStorage()?.getItem(TRACE_ID_STORAGE) || ''
  }

  createVisitorId(): string {
    const PREFIX_VISITOR_ID_VALUE = 'fba'
    const uuidGenerated: string = uuid()
      .replace(/[^A-Za-z0-9\s]/g, '')
      .substring(0, 97) // due to adobe limit (100 characters)

    return `${PREFIX_VISITOR_ID_VALUE}${uuidGenerated}`
  }

  isConsentInitialized(): boolean {
    return this.storageSdk.localStorage()?.getItem(CONSENT_ID_STORAGE) != null
  }

  isExemptInitialized(): boolean {
    return this.storageSdk.localStorage()?.getItem(EXEMPT_ID_STORAGE) != null
  }

  resetConsentIdsIfNecessary(callback: () => Promise<void>): void {
    if (canUseDom()) {
      const cookieVisitorIdChanged = Cookies.get(VISITOR_ID_COOKIE_CHANGED)
      const consentAlreadyInitialized = this.isConsentInitialized() || this.isExemptInitialized()

      if (cookieVisitorIdChanged && consentAlreadyInitialized) {
        callback().then(() => Cookies.remove(VISITOR_ID_COOKIE_CHANGED))
      }
    }
  }

  getOrInitVisitorId(): string | undefined {
    // Le dépôt du cookie est désactivé sur les domaines hors connect/localhost afin d'empêcher notre widget externe de le déposer sur les sites partenaires
    if (canUseDom() && window.location.hostname.match(/^(?:[a-z0-9-]+\.)?(?:test-)?sncf-connect\.com|^localhost$/i)) {
      const cookieVisitorId = Cookies.get(VISITOR_ID_COOKIE)

      if (cookieVisitorId) {
        return cookieVisitorId
      }
      const newVisitorId = this.createVisitorId()
      const expiryDate = new Date()
      expiryDate.setFullYear(expiryDate.getFullYear() + 1)
      Cookies.set(VISITOR_ID_COOKIE, newVisitorId, {
        secure: true,
        path: '/',
        expires: expiryDate,
        sameSite: 'Lax',
      })

      return newVisitorId
    }

    return undefined
  }

  getConsentVendors(): string {
    return this.storageSdk.localStorage()?.getItem(CONSENT_VENDORS_S2S_STORAGE) || ''
  }

  getConsentString(): string {
    const consentString = this.storageSdk.localStorage()?.getItem(CONSENT_STRING_STORAGE)
    if (consentString && consentString !== 'undefined') return consentString

    return this.storageSdk.localStorage()?.getItem(UE_CONSENT_V2) || ''
  }

  getConsentId(): string {
    return this.storageSdk.localStorage()?.getItem(CONSENT_ID_STORAGE) || ''
  }

  getExemptId(): string {
    return this.storageSdk.localStorage()?.getItem(EXEMPT_ID_STORAGE) || ''
  }

  getVisitorType(): string {
    return this.storageSdk.localStorage()?.getItem(VISITOR_TYPE_STORAGE) || VisitorType.NEW_USER
  }

  getEmailHidden(): string | undefined {
    const emailHiddenRaw = this.storageSdk.getExpirableStringFromLocal(EMAIL_HIDDEN_STORAGE)

    return emailHiddenRaw?.value || undefined
  }

  setEmailHidden(emailHidden: string): void {
    this.storageSdk
      .localStorage()
      ?.setItem(
        EMAIL_HIDDEN_STORAGE,
        StringExpirableDataMapper.toJSON(new EmailData(emailHidden).resetExpirationDate())
      )
  }

  getEmailStrong(): string | undefined {
    const emailData = this.storageSdk.getExpirableStringFromLocal(EMAIL_STRONG_STORAGE)

    return emailData?.value || undefined
  }

  setEmailStrong(emailStrong: string): void {
    this.storageSdk
      .localStorage()
      ?.setItem(
        EMAIL_STRONG_STORAGE,
        StringExpirableDataMapper.toJSON(new EmailData(emailStrong).resetExpirationDate())
      )
  }

  getEmailStronger(): string | undefined {
    const emailData = this.storageSdk.getExpirableStringFromLocal(EMAIL_STRONGER_STORAGE)

    return emailData?.value || undefined
  }

  setEmailStronger(emailStronger: string): void {
    this.storageSdk
      .localStorage()
      ?.setItem(
        EMAIL_STRONGER_STORAGE,
        StringExpirableDataMapper.toJSON(new EmailData(emailStronger).resetExpirationDate())
      )
  }

  getPreviousData(): string {
    const previousData = this.storageSdk.getNavigationPrevious()

    if (previousData) {
      const { previousPageName, clickPosition } = previousData

      return clickPosition ? `${previousPageName}:${clickPosition}` : previousPageName
    }

    return ''
  }

  getLastTrackedPage = (): string => {
    const previousData = this.storageSdk.getNavigationPrevious()

    return previousData?.previousPageName || ''
  }

  getDeferredEventsPush(): Set<TrackedEvent> {
    const eventsString = this.storageSdk.sessionStorage()?.getItem(EVENTS_PUSH_STORAGE)

    return eventsString ? this.getDeferredEvents(eventsString, 'PUSH') : new Set<TrackedEvent>()
  }

  getDeferredEventsClick(): Set<TrackedEvent> {
    const eventsString = this.storageSdk.sessionStorage()?.getItem(EVENTS_CLICK_STORAGE)

    return eventsString ? this.getDeferredEvents(eventsString, 'CLICK') : new Set<TrackedEvent>()
  }

  getDeferredEvents(eventsString: string, eventType: TrackedEventEventTypeEnum): Set<TrackedEvent> {
    const trackedEvents: Set<TrackedEvent> = new Set<TrackedEvent>()

    try {
      const trackedEventsParsed = JSON.parse(eventsString)

      if (Array.isArray(trackedEventsParsed)) {
        for (let i = 0; i < trackedEventsParsed.length; i++) {
          const item = trackedEventsParsed[i]

          if (typeof item === 'string') {
            trackedEvents.add(<TrackedEvent>{
              event: item,
              eventType,
              pageName: '',
              isInternal: true,
            })
          } else {
            trackedEvents.add(item)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

    return trackedEvents
  }

  getCriteoId(): string {
    return this.storageSdk.getExpirableStringFromLocal(CRITEO_ID)?.value || ''
  }

  getSearchUsage(): string {
    return this.storageSdk.sessionStorage()?.getItem(SEARCH_USAGE_STORAGE) || ''
  }

  getHeaders = (): { [key: string]: string } => {
    this.getOrInitVisitorId()
    const headers: { [key: string]: string } = {}
    headers[CHANNEL_HEADER] = this.user.deviceSettings?.channel as string
    headers[CLIENT_APP_ID_HEADER] = this.user.deviceSettings?.deviceId as string
    headers[VIRTUAL_ENV_NAME] = this.user.deviceSettings?.virtualEnvName as string
    headers[ENV_HEADER] = this.user.deviceSettings?.env as string
    headers[LOCALE_HEADER] = this.user.deviceSettings?.clientMarket as string
    headers[EMAIL_HIDDEN_HEADE] = this.getEmailHidden() as string
    headers[EMAIL_STRONG_HEADER] = this.getEmailStrong() as string
    headers[EMAIL_STRONGER_HEADER] = this.getEmailStronger() as string
    headers[CONSENT_VENDORS_HEADER] = this.getConsentVendors()
    headers[CONSENT_STRING_HEADER] = this.getConsentString()
    headers[CONSENT_ID_HEADER] = this.getConsentId()
    headers[CONSENT_EXEMPT_ID_HEADER] = this.getExemptId()
    headers[APP_VERSION_HEADER] = APP_VERSION_VALUE
    headers[BFF_KEY_HEADER] = BFF_KEY_VALUE
    headers[DEVICE_OS_VERSION_HEADER] = this.user.deviceSettings?.osVersion as string
    headers[DEVICE_CLASS_HEADER] = this.user.deviceSettings?.deviceClass as string
    headers[ATTRIBUTION_MEDIUM_HEADER] = this.getParamFromUrl(WIZ_MEDIUM)
    headers[ATTRIBUTION_SOURCE_HEADER] = this.getParamFromUrl(WIZ_SOURCE)
    headers[ATTRIBUTION_CAMPAIGN_HEADER] = this.getParamFromUrl(WIZ_COMPAIGN)
    headers[ATTRIBUTION_TERM_HEADER] = this.getParamFromUrl(WIZ_TERM)
    headers[ATTRIBUTION_CONTENT_HEADER] = this.getParamFromUrl(WIZ_CONTENT)
    headers[ATTRIBUTION_PREX_HEADER] = this.getParamFromUrl(PREX)
    headers[ATTRIBUTION_ID_COMPTEUR_HEADER] = this.getParamFromUrl(ID_COMPTEUR)
    headers[ATTRIBUTION_EFFI_ID_HEADER] = this.getParamFromUrl(EFFI_ID)
    headers[ATTRIBUTION_EFFI_ID2_HEADER] = this.getParamFromUrl(EFFI_ID2)
    headers[ATTRIBUTION_CRM_HEADER] = this.getCrm()
    headers[ATTRIBUTION_CLID_HEADER] = this.getClid()
    headers[ATTRIBUTION_GCLID_HEADER] = this.getGclid()
    headers[ATTRIBUTION_REFERRER_HEADER] = canUseDom() ? document.referrer : ''
    headers[NAVIGATION_PREVIOUS_HEADER] = this.getPreviousData()
    headers[NAVIGATION_CURRENT_HEADER] = canUseWindow() ? window.location.pathname : ''
    headers[VISITOR_TYPE_HEADER] = VisitorTypeDataMapper.fromJSON(this.getVisitorType()).visitorType
    headers[NAVIGATION_PAGE_PARAMS] = this.getAttributionPageParams()
    headers[NAVIGATION_SESSION] = this.getNavigationSession()
    headers[TRACE_ID_HEADER] = this.getTraceId()
    headers[FLAGSHIP_HEADER] = this.getFlagship()
    headers[CRITEO_ID_HEADER] = this.getCriteoId()
    headers[ZEMANTA_ID_HEADER] = this.getZemantaId()
    headers[SEARCH_USAGE_HEADER] = this.getSearchUsage()
    headers[ABTASTY_HEADER] = this.getABTasty()
    Object.keys(headers).forEach((key) =>
      headers[key] === null || headers[key] === undefined || headers[key] === '' ? delete headers[key] : {}
    )

    return headers
  }

  getFlagship(): string {
    if (canUseDom()) {
      const flagDataJson = sessionStorage.getItem(FLAGSHIP_USER_CAMPAIGNS)

      if (flagDataJson) {
        const { campaignId, variationId } = JSON.parse(flagDataJson)

        return `${campaignId}|${variationId}`
      }
    }

    return ''
  }

  getAttributionPageParams = (): string =>
    canUseWindow() && this.isParamsTrackable(window.location.search) ? encodeURI(window.location.search) : ''

  getParamFromUrl(param: string): string {
    const paramValue = canUseWindow() ? new URLSearchParams(encodeURI(window.location.search)).get(param) : null

    return paramValue ? encodeURIComponent(paramValue) : ''
  }

  getNavigationSession(): string {
    const sessionIdDataplatform = this.storageSdk.getSessionId()

    return sessionIdDataplatform.toHeaderString()
  }

  // return PostBackId if present, wizclid otherwise
  getClid(): string {
    // Deleting older Clid if existing (previous campaign) but new campaign has been launched
    if (canUseWindow() && this.isParamsTrackable(window.location.search)) {
      this.storageSdk.localStorage()?.removeItem(ATTRIBUTION_CLID_STORAGE)
    }
    const postBackId = canUseWindow() ? new URLSearchParams(window.location.search).get(POST_BACK_ID) : null
    const wizClid = canUseWindow() ? new URLSearchParams(window.location.search).get(WIZ_CLID) : null

    if (postBackId || wizClid) {
      this.storageSdk
        .localStorage()
        ?.setItem(
          ATTRIBUTION_CLID_STORAGE,
          StringExpirableDataMapper.toJSON(new ClidData(postBackId || wizClid || '').resetExpirationDate())
        )
    }

    const clid = this.storageSdk.getExpirableStringFromLocal(ATTRIBUTION_CLID_STORAGE)

    return encodeURIComponent(clid?.value || '')
  }

  getGclid(): string {
    // Deleting older GCLID if existing (previous campaign) but new campaign has been launched
    if (canUseWindow() && this.isParamsTrackable(window.location.search)) {
      this.storageSdk.localStorage()?.removeItem(ATTRIBUTION_GCLID_STORAGE)
    }
    const paramValue = canUseWindow() ? new URLSearchParams(window.location.search).get(GCLID) : null

    if (paramValue) {
      this.storageSdk
        .localStorage()
        ?.setItem(
          ATTRIBUTION_GCLID_STORAGE,
          StringExpirableDataMapper.toJSON(new GclidData(paramValue).resetExpirationDate())
        )
    }
    const gclidData = this.storageSdk.getExpirableStringFromLocal(ATTRIBUTION_GCLID_STORAGE)

    return encodeURIComponent(gclidData?.value || '')
  }

  getZemantaId(): string {
    const paramValue = canUseWindow() ? new URLSearchParams(window.location.search).get(ZEMANTA_ID) : null

    if (paramValue) {
      this.storageSdk
        .localStorage()
        ?.setItem(ZEMANTA_ID_STORAGE, StringExpirableDataMapper.toJSON(new ZaidData(paramValue).resetExpirationDate()))
    }

    const zaidData = this.storageSdk.getExpirableStringFromLocal(ZEMANTA_ID_STORAGE)

    return encodeURIComponent(zaidData?.value || '')
  }

  getCrm(): string {
    const paramValue = canUseWindow() ? new URLSearchParams(window.location.search).get(WIZ_CRM) : null

    // checking if CRM is 64 characters and base64 compliant
    if (paramValue && base64Format.test(paramValue) && paramValue.length === crmLength) {
      this.storageSdk
        .localStorage()
        ?.setItem(
          ATTRIBUTION_CRM_STORAGE,
          StringExpirableDataMapper.toJSON(new CrmData(paramValue).resetExpirationDate())
        )
    }

    const crm = this.storageSdk.getExpirableStringFromLocal(ATTRIBUTION_CRM_STORAGE)

    return encodeURIComponent(crm?.value || '')
  }

  getBffHeader = (): string => this.getHeaders()[BFF_KEY_HEADER]

  createAxiosOptions = (shouldRefreshTokens = true, options?: AxiosRequestConfig): AugmentedAxiosRequestConfig => ({
    headers: { ...this.getHeaders(), ...options?.headers },
    ...(options?.params && { params: { ...options?.params } }),
    // Namespace to avoid potential conflicts with official Axios options
    ivts: {
      shouldRefreshTokens,
    },
  })

  getABTasty(): string {
    if (canUseWindow()) {
      const result = window.ABTasty?.getTestsOnPage()

      if (result) {
        return Object.keys(result)
          .map((key) => `${key}|${result[key].variationID}`)
          .join(',')
      }
    }

    return ''
  }

  private isParamsTrackable = (params: string): boolean => attributionParamsToTrack.some((ele) => params.includes(ele))
}
